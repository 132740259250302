/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $('.data-image').each(function () {
                    jQuery(this).attr('src', jQuery(this).data('src'));
                });
                $('[data-toggle="tooltip"]').tooltip();
                $('#menu-main-menu').find('.dropdown').each(function () {
                    $(this).children('a').each(function () {
                        var url = $(this).attr('href');
                        if (url !== '#') {
                            $(this).attr('data-url', url);
                            // $(this).attr('href','#');
                        }
                    });
                });
                $('#menu-main-menu li.dropdown > a').click(function (e) {
                    if ($(window).width() < 1025) {
                        var grandparent = $(this).next();
                        if ($(this).hasClass('open1') && grandparent.hasClass('opened')) {
                            document.location.href = this.href;
                        } else {
                            $(this).addClass('open1');
                            $('#menu-main-menu').find("i").removeClass('opened');
                            $(this).next(".dropdown-toggle1").click();
                        }
                    } else {
                        location.href = this.href;
                    }
                    e.preventDefault();
                });
                $("li.dropdown > .dropdown-toggle1").on("click", function (e) {
                    var current = $(this).next();
                    var current1 = $(this);
                    var grandparent = $(this).parent().parent();
                    grandparent.find(".dropdown-menu:visible").not(current).removeClass('show1');
                    grandparent.find(".dropdown-toggle1").not(current1).removeClass('opened');
                    grandparent.addClass('open1111');
                    $(this).toggleClass('opened');
                    current.toggleClass('show1');
                    e.stopPropagation();
                });
                $(window).scroll(function () {
                    var scroll = $(window).scrollTop();
                    if (scroll >= 100) {
                        $(".sticky-site-header").addClass("shadow_css");
                    } else {
                        $(".sticky-site-header").removeClass("shadow_css");
                    }
                });











            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
            
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
